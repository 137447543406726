import { useState, useEffect } from "react";
import ButtonOrange from "../utils/ButtonOrange";
import { getAdmin } from "../lib/auth";
import api from "../lib/api";
import Footer from "../utils/Footer";

import { X } from "@phosphor-icons/react";
import { formatDate } from "../utils/formatDate";

export default function Events() {
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [days, setDays] = useState<number>(21);
  const [createEvent, setCreateEvent] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);

  getAdmin(); //Verifica se o usuário é admin

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name,
      startAt: date,
      days,
      active
    };
    console.log(data);
    const response = await api.post("/events", data, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    getEvents()
    // Verifique a resposta do servidor, redirecione se necessário
    //if (response.status === 200) {
    //  alert(response.data);
    //  window.location.reload();
    //} else {
    //  console.error("Erro no servidor:", response.status);
    //}
  };

  async function getEvents() {
    await api.get("/events", {
      headers: {
        "Content-Type": "application/json"
      },
      validateStatus: () => true
    }).then((response) => {
      setEvents(response.data);
    })
  }

  useEffect(() => {
    getEvents();
  }, [])

  return (
    <div>
      {createEvent && (
        <>
          <div className="flex justify-center items-center">
            <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">Criar evento</h1>
          </div>
          <div className="flex justify-center">
            <div className="w-full max-w-md px-4">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex justify-end text-right">
                  <X size={32} weight="bold" className="w-1/12 cursor-pointer" onClick={() => setCreateEvent(false)}/>
                </div>
                <div>
                  <label htmlFor="name">Nome do evento:</label>
                  <input
                    className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="date">Data de início:</label>
                  <input
                    className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                    type="date"
                    name="date"
                    id="date"
                    value={date}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="days">Dias de evento:</label>
                  <input
                    className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                    type="number"
                    name="days"
                    id="days"
                    value={days}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDays(parseInt(e.target.value));
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="active-checkbox" className="flex items-center">Status do evento:</label>
                  <input
                    type="checkbox"
                    id="active-checkbox"
                    checked={active}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setActive(e.target.checked);
                    }}
                    className="mr-2"
                  />
                  {active ? 'Ativo' : 'Desativado'}
                </div>
                <div>
                  <ButtonOrange title="Criar evento" />
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      <div>
      <div className="flex justify-center items-center gap-10">
        <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">Eventos</h1>
        <div className="flex font-bold mt-8 lg:mt-28 mb-4 lg:mb-16">
          <ButtonOrange title="Criar evento" onClick={() => setCreateEvent(!createEvent)} />
        </div>
      </div>
      <div>
        {events.map((event) => (
          <div key={event.id} className="rounded-lg border-solid border p-4 mb-4 mx-20">
            <h2 className="text-lg font-semibold">{event.name}</h2>
            <p className="">Data de início: {formatDate(event.startAt, "date")}</p>
            <p className="">Dias de evento: {event.days}</p>
            <p className="">Status: {event.active ? 'Ativo' : 'Desativado'}</p>
            <p className="">Dias:</p>
            <div className="flex flex-wrap gap-2 justify-center">
              {event.day.map((day: any) => (
                <div key={day.id} className="rounded-lg border-solid border p-2 w-[13%]">
                <p>Dia: {day.day}</p>
                <p>Data: {formatDate(day.date, "date")}</p>
                <p>Pontos duplos: {day.doublePoints ? "Sim" : "Não"}</p>
                <p>Envios: {day.UserChallenge.length}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}
