import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function ShowNavbar({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/painelAdm") {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [location]);
  return <div>{showNav && children}</div>;
}

export default ShowNavbar;
