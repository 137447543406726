import React, { useEffect, useState } from "react";
import api from "../lib/api";
import { Check, ImagePlus } from "lucide-react";
import ButtonOrange from "../utils/ButtonOrange";
import { getUser } from "../lib/auth";
import Footer from "../utils/Footer";

function TodayChallenges() {
  const [days, setDays] = useState<any[]>([]);
  const [challenges, setChallenges] = useState<any[]>([]);
  const [challengesImg, setChallengesImg] = useState<any[]>([]);
  const [userChallenges, setUserChallenges] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maxFileSize = 5 * 2000000; // 10MB

  function formatDate(date: Date): string {
    return date
      .toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })
      .slice(0, 5);
  }

  useEffect(() => {
    async function getDays() {
      const response = await api.get("/days/challenge");
      setDays(response.data);
      console.log(response.data);
    }

    getDays();
  }, []);

  useEffect(() => {
    async function getChallenges() {
      const response = await api.get("/challenges/challenge");
      setChallenges(response.data);
    }

    getChallenges();
  }, []);

  useEffect(() => {
    async function getUserChallenges() {
      const userId = getUser().sub;

      const response = await api.get(`/userChallenges/${userId}`);
      console.log(response.data)
      setUserChallenges(response.data);
    }

    getUserChallenges();
  }, []);

  useEffect(() => {
    console.log(userChallenges);
  }, [userChallenges]);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const day = event.target.dataset.dayid; //Pega o id do dia
    const challengeId = event.target.dataset.challengeid; //Pega o id do desafio
    console.log(event.target.dataset)
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size < maxFileSize) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0] as Blob);

        //Remove a imagem do desafio caso já exista
        if (challengesImg.find((img) => img.day === day && img.challengeId === challengeId)) {
          const index = challengesImg.findIndex((img) => img.day === day && img.challengeId === challengeId);
          challengesImg.splice(index, 1);
        }

        //Adiciona a imagem do desafio
        reader.onload = function () {
          const photoDataURL = reader.result as string;
          setChallengesImg([...challengesImg, { file: photoDataURL, day, challengeId }]);
        };
      } else {
        alert("Tamanho máximo de arquivo excedido! (10MB)");
      }
    }
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(event.target)
    const userChallenge = [];

    for (let i = 0; i < challengesImg.length; i++) {
      const userC = {
        userId: getUser().sub,
        photoURL: challengesImg[i].file,
        dayId: challengesImg[i].day,
        challengeId: challengesImg[i].challengeId
      };
      console.log(challengesImg[i])
      userChallenge.push(userC);
    }

    setIsLoading(true);

    const response = await api.post("/userChallenges", userChallenge, {
      headers: {
        "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
      }
    });

    if (response.status === 200) {
      //alert("Desafio(s) enviado(s) com sucesso!");
      window.location.reload();
    } else {
      console.error("Erro no servidor:", response.status);
    }

    setIsLoading(false);
  }
  return (
    <div className="absolute z-[-1] mt-20">
      <form onSubmit={handleSubmit}>
        <table className="border-separate mx-[-80px] lg:mx-0 border-spacing-x-24 border-spacing-y-10">
          <thead>
            <tr>
              <th className="w-60 flex pt-6">Desafios</th>
              {days.map((item, index) => {
                return (
                  <>
                    {item.isActive ? (
                      <>
                        {item.doublePoints ? (
                          <td className="whitespace-nowrap text-center text-orange-500 ">
                            Dia {item.day} <p className="text-orange-500">2x</p>
                            {formatDate(new Date(item.date))}
                          </td>
                        ) : (
                          <td className="text-orange-500 whitespace-nowrap">
                            Dia {item.day}
                            <span className="text-sm block">{formatDate(new Date(item.date))}</span>
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        {item.doublePoints ? (
                          <td className="whitespace-nowrap text-center">
                            Dia {item.day}
                            <span className="text-sm block">{formatDate(new Date(item.date))}</span>
                            <p className="text-orange-500">2x</p>
                          </td>
                        ) : (
                          <td className="whitespace-nowrap">
                            Dia {item.day}
                            <span className="text-sm block">{formatDate(new Date(item.date))}</span>
                          </td>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </tr>
            {challenges.map((item, index) => {
              return (
                <>
                  <tr>
                    <td>{item.title}</td>
                    {(() => {
                      let td = [];
                      for (let i = 0; i < days.length; i++) {
                        td.push(
                          <td key={i}>
                            {days[i].isActive === false ? (
                              <div>
                                <label htmlFor={`${days[i].id}${item.id}`}>
                                  {userChallenges.find((challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id) ? (
                                    <>
                                      {userChallenges.find(
                                        (challenge) =>
                                          challenge.dayId === days[i].id && challenge.challengeId === item.id && challenge.isValidated === true
                                      ) ? (
                                        <Check className="w-8 h-8  text-green-700" />
                                      ) : (
                                        <img
                                          src={
                                            userChallenges.find((challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id)
                                              ?.photoURL
                                          }
                                          alt=""
                                          className="w-16 h-16 translate-y-[-50%] translate-x-[-25%] absolute overflow-visible opacity-20"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <ImagePlus className="w-8 h-8  opacity-30" />
                                  )}
                                </label>
                                <input disabled className="hidden" type="file" name="photo" id={`${days[i].id}${item.id}`} />
                              </div>
                            ) : (
                              <div>
                                <label htmlFor={`${days[i].id}${item.id}`}>
                                  {userChallenges.find(
                                    (challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id && challenge.isValidated === true
                                  ) ? (
                                  <div className="relative flex items-center justify-center w-16 h-16">
                                    <img
                                      src={
                                        userChallenges.find(
                                          (challenge) =>
                                            challenge.dayId === days[i].id && challenge.challengeId === item.id
                                        )?.photoURL
                                      }
                                      alt=""
                                      className="w-full h-full object-cover"
                                    />
                                    <Check className="w-8 h-8 text-green-700 absolute z-10" />
                                  </div>
                                  ) : (
                                    <>
                                      {challengesImg.find((img) => img.day === days[i].id && img.challengeId === item.id) ? (
                                            <div className="flex justify-center items-center">
                                        <img
                                          src={challengesImg.find((img) => img.day === days[i].id && img.challengeId === item.id)?.file}
                                          alt=""
                                          className="max-w-16 max-h-16 cursor-pointer overflow-visible object-cover"
                                        />
                                            </div>
                                      ) : (
                                        <>
                                          {userChallenges.find((challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id) ? (
                                            <div className="flex justify-center items-center">
                                            <img
                                              src={
                                                userChallenges.find((challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id)
                                                  ?.photoURL
                                              }
                                              alt=""
                                              className="max-w-16 max-h-16 overflow-visible object-cover"
                                            />
                                            </div>
                                          ) : (
                                            <div className="flex justify-center items-center">
                                              <ImagePlus className="w-8 h-8 cursor-pointer hover:text-orange-500" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </label>
                                {userChallenges.find((challenge) => challenge.dayId === days[i].id && challenge.challengeId === item.id) ? null : (
                                  <input
                                    className="hidden"
                                    accept="image/png, image/jpeg"
                                    type="file"
                                    name="photo"
                                    id={`${days[i].id}${item.id}`}
                                    data-challengeId={item.id}
                                    data-dayId={days[i].id}
                                    onChange={onImageChange}
                                  />
                                )}
                              </div>
                            )}
                          </td>
                        );
                      }
                      return td;
                    })()}
                  </tr>
                </>
              );
            })}
          </thead>
        </table>
        {challengesImg.length > 0 ? (
          <div className="w-[25%] left-[50%] translate-x-[-50%] sticky">
            <ButtonOrange disabled={isLoading} isLoading={isLoading} title="Enviar Desafio(s)" />
          </div>
        ) : null}
      </form>
      {/* <div className="mt-5">
        <Footer></Footer>
      </div> */}
    </div>
  );
}

export default TodayChallenges;
