export function formatDate(dataString: string, type?: string): string {
    const data = new Date(dataString);
    //data.setHours(data.getHours() - 3);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const segundos = String(data.getSeconds()).padStart(2, "0");
    if (type == "date") return `${dia}/${mes}/${ano}`;
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  }
  