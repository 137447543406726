import React, { useEffect, useState } from "react";
import api from "../lib/api";
import { isAdmin } from "../lib/auth";
import imgdefault from "../../assets/default.jpg";
import Footer from "../utils/Footer";
import { CaretDoubleDown, CaretDoubleUp, List } from "@phosphor-icons/react";

function Ranking() {
  const [users, setUsers] = useState<any[]>([]);
  const [admin, setAdmin] = useState(false);
  const [edit, setEdit] = useState(false);

  isAdmin().then((response) => {
    setAdmin(response);
  });

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await api.get("/ranking");
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    getUsers();
  }, []);

  const increasePoints = async (userId: string) => {
    try {
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            totalPoints: user.totalPoints + 1,
            hasChanged: true
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error(error);
    }
  };

  const decreasePoints = async (userId: string) => {
    try {
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            totalPoints: user.totalPoints - 1,
            hasChanged: true
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEdit = async (users: any[]) => {
    users.forEach(async (element) => {
      if (element.hasChanged) {
        // Chamar API para salvar os pontos do usuário
        const teste = await api.get("/teste");
        console.log(teste.data);

        const response = await api.post("/ranking", element, {
          headers: {
            "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
          }
        });

        console.log(response.data);

        // console.error("Erro ao salvar os pontos");
        // return
      }
    });

    setEdit(false);
  };

  return (
    <div>
      <div className="text-center mb-10">
        <div className="flex justify-center items-center flex-col">
          <h1 className="text-center text-orange-400 font-bold mt-10 lg:mt-28 text-2xl lg:text-5xl">Ranking</h1>
          {admin && (
            <div className="flex justify-center items-center gap-10 mt-1 lg:mt-18">
              {!edit && (
                <button type="button" onClick={() => setEdit(true)}>
                  Editar
                </button>
              )}
              {!!edit && (
                <button type="button" onClick={() => saveEdit(users)}>
                  Salvar
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className=""
      >
        {users.map((item, index) => (
          <div className="p-2 flex items-center justify-center lg:gap-5 lg:w-1/2 lg:mx-auto" key={index}>
            <div className="w-[10%] flex justify-center">
              <h1 className="lg:text-3xl text-lg font-bold text-white">
                {index + 1}º
              </h1>
            </div>
            <div className="w-[10%] flex justify-center">
              {(item.pastPosition < index + 1 && item.pastPosition !== 0) && <CaretDoubleDown size={32} weight="bold" className="text-red-600"/>}
              {(item.pastPosition === index + 1 || item.pastPosition === 0) && <List size={32} weight="bold" className="text-white"/>}
              {(item.pastPosition > index + 1 && item.pastPosition !== 0) && <CaretDoubleUp size={32} weight="bold" className="text-green-600"/>}
            </div>
            <div className="w-[15%] flex justify-center">
              {item.avatarURL ? (
                <img src={item.avatarURL} alt="Foto do usuário" className="lg:rounded-xl rounded-md lg:w-20 lg:h-20 w-10 h-10 object-cover" />
              ) : (
                <img src={imgdefault} alt="Foto do usuário" className="lg:rounded-xl rounded-md lg:w-20 lg:h-20 w-10 h-10" />
              )}
            </div>
            <div className="w-[10%]">
              <p className="text-center text-sm">{item.totalPoints}</p>
            </div>
            <div className="w-[55%]">
              <h1 className="lg:text-3xl text-sm font-bold text-white text-left">
                {item.name}
              </h1>
            </div>
            {edit && (
              <div className="flex justify-center">
                <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={() => increasePoints(item.id)}>
                  +
                </button>
                <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded ml-2" onClick={() => decreasePoints(item.id)}>
                  -
                </button>
              </div>
            )}
          </div>
        ))}
        {/* <Footer></Footer> */}
      </div>
    </div>
  );
}

export default Ranking;
