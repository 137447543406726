import { CirclePlus, Menu, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavbarData } from "./NavbarData";
import { getUser, isAdmin, logout } from "../lib/auth";
import "./Navbar.css";
import ButtonTransparent from "../utils/ButtonTransparent";
import { LogOut } from "lucide-react";
import "../lib/auth";
import api from "../lib/api";
import Cookies from "js-cookie";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [avatar, setAvatar] = useState("");
  const maxFileSize = 5 * 2000000; // 10MB
  const user = getUser();

  const handleSidebarChange = (State: boolean) => {
    setSidebar(State);
  };

  isAdmin().then((response) => {
    setAdmin(response);
  });

  function logoutUser() {
    logout();
  }

  useEffect(() => {
    async function uploadAvatar() {
      const userAvatar = {
        userId: user.sub,
        avatarURL: avatar
      };

      try {
        const response = await api.put("/register", userAvatar, {
          headers: {
            "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
          }
        });

        if (response.status === 200) {
          const data = response.data;

          Cookies.set("token", data, { expires: 3 });
          window.location.reload();
        } else {
          console.error("Erro no servidor:", response.status);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (avatar !== "") {
      uploadAvatar();
    }
  }, [avatar]);

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log("Entrei");

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > maxFileSize) {
        alert("O tamanho do arquivo é muito grande, por favor selecione um arquivo menor que 10MB");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0] as Blob);

      reader.onload = function () {
        const photoDataURL = reader.result as string;
        setAvatar(photoDataURL);
      };
    }
  }

  return (
    <nav className="bg-orange-400 p-4 flex justify-center items-center w-full fixed gap-20">
      <div>
        <Link to="#">
          {sidebar ? (
            <X className="text-orange-900 h-8 w-8 md:hidden" onClick={() => handleSidebarChange(false)} />
          ) : (
            <Menu className="text-orange-900 h-8 w-8 md:hidden" onClick={() => handleSidebarChange(true)} />
          )}
        </Link>
      </div>
      <div className="hidden md:block">
        <div className="img_wrapper relative">
          <label htmlFor="avatar" className="cursor-pointer">
            {user.avatarURL ? (
              <div className="flex items-center gap-2">
                <img src={user.avatarURL} alt="Foto de Perfil" className="rounded-full w-8 h-8" />
                <p>{user.name}</p>
              </div>
            ) : (
              <>
                <span className="description font-bold opacity-0 hidden absolute text-sm left-[50%] top-[50%] transform -translate-y-1/2 -translate-x-1/2">
                  Adicionar Foto de Perfil
                </span>
                <CirclePlus className="icon w-8 h-8" />
              </>
            )}
          </label>
          <input accept="image/png, image/jpeg" className="hidden" type="file" name="avatar" id="avatar" onChange={handleFileChange} />
        </div>
      </div>
      <div className="flex items-center justify-center hidden md:flex">
        {NavbarData.map((item, index) => {
          return (
            <div>
              {admin ? (
                <Link to={item.path}>
                  <li key={index} className="list-none px-4 p-4 font-bold w-full text-xl items-center hover:bg-white hover:text-orange-400">
                    {item.title}
                  </li>
                </Link>
              ) : null}

              {!admin && item.isAdminPage === false ? (
                <li key={index} className="list-none px-4 p-4 font-bold w-full text-xl items-center hover:bg-white hover:text-orange-400">
                  <Link to={item.path}>{item.title}</Link>
                </li>
              ) : null}
            </div>
          );
        })}
      </div>

      <div>
        <ButtonTransparent onClick={() => logoutUser()} title="Sair" Icon={LogOut} />
      </div>
      {sidebar && (
        <div className="bg-orange-400 p-4 absolute top-16 left-0 right-0 md:hidden">
          <ul className="list-none space-y-2">
            {NavbarData.map((item, index) =>
              !admin && item.isAdminPage ? null : (
                <Link to={item.path} key={index}>
                  <li className="list-none font-bold text-white hover:text-orange-900">{item.title}</li>
                </Link>
              )
            )}
          </ul>
        </div>
      )}
    </nav>
  );
}
export default Navbar;
