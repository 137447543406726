import Cookies from "js-cookie";
import img from "../../assets/login.png";
import logo from "../../assets/logo_ac.png";
import api from "../lib/api";
import ButtonGreen from "../utils/ButtonGreen";
import { useEffect, useState } from "react";
import Footer from "../utils/Footer";
import { useSearchParams } from 'react-router-dom';

function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 6000);
  }, [error]);

  async function userLogin(login: string, password: string) {
      const userData = {
      login,
      password
    }
    try {
      const response = await api.post("/login", userData, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.status === 200) {
        const data = response.data;
        Cookies.set("token", data, { expires: 3 });
        window.location.href = "/";
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      setError("Email ou senha incorretos");
    }
  }

  useEffect(() => {
    const email = searchParams?.get("email");
    const pass = searchParams?.get("senha");
    if (email && pass) {
      setLogin(email)
      setPassword(pass)
      userLogin(email, pass)
    }
  }, []);

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const userData = {
      login,
      password
    };

    try {
      const response = await api.post("/login", userData, {
        headers: {
          "Content-Type": "application/json" // Define o cabeçalho Content-Type como application/json
        }
      });

      // Verifique a resposta do servidor, redirecione se necessário
      if (response.status === 200) {
        const data = response.data;
        Cookies.set("token", data, { expires: 3 });
        window.location.href = "/"; // Redireciona para a página inicial
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      setError("Email ou senha incorretos");
    }
  }

  return (
    <div className="">
      <div>
        {error && (
          <div
            id="error"
            className="bg-red-500 mt-8 transition-opacity duration-300 text-white p-2 text-center absolute left-[50%] translate-x-[-50%] z-10 rounded"
          >
            <span className="m-16 font-bold">{error}</span>
          </div>
        )}
      </div>
      <div className="min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: `url(${img})` }}>
        <div className="bg-white p-6 rounded-lg shadow-md w-full lg:w-1/4">
          <div className="w-full lg:w-full">
            <div className="flex justify-center">
              <img src={logo} className="w-1/2" />
            </div>
            <form onSubmit={handleSubmit} className="text-black">
              <div className="mb-4 lg:mb-8">
                <label htmlFor="login">LOGIN:</label>
                <input
                  className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                  type="text"
                  name="login"
                  id="login"
                  value={login}
                  onChange={handleLoginChange}
                  placeholder="Digite seu login"
                />
              </div>
              <div className="mb-4 lg:mb-8">
                <label htmlFor="password">SENHA:</label>
                <br />
                <input
                  className="mt-2 lg:mt-4 w-full border-b bg-transparent border-gray-600 px-2 py-1 focus:border-blue-500 focus:outline-none"
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Digite sua senha"
                />
              </div>
              <ButtonGreen title="Entrar" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
