import { useState, useEffect } from "react";
import api from "../lib/api";
import PainelAdmUser from "./PainelAdmUser";
import { Link } from "react-router-dom";
import imgdefault from "../../assets/default.jpg";
import Footer from "../utils/Footer";

const Participantes = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await api.get("/isAdmin/userInfo");
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    getUsers();
  }, []);

  return (
    <div className="text-center">
      <div className="flex justify-center items-center">
        <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">Participantes</h1>
      </div>

      <nav>
        <div className="flex mt-8 sm:mt-16 flex-wrap justify-center items-center">
          {users.map((item, index) => {
            return (
              <div className="ml-4 sm:ml-8 mb-8 sm:mb-16" key={index}>
                <Link to="/painelAdm" state={{ info: item }} className="text-lg sm:text-3xl font-bold mb-2 sm:mb-8 text-white cursor-pointer">
                  {item.avatarURL && <img src={item.avatarURL} alt="Foto do usuário" className="rounded-3xl w-24 sm:w-40 mb-2 sm:mb-8" />}
                  {item.avatarURL === null && <img src={imgdefault} alt="Foto do usuário" className="rounded-3xl w-24 sm:w-40 mb-2 sm:mb-8" />}
                  {item.name}
                </Link>
              </div>
            );
          })}
        </div>
      </nav>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default Participantes;
