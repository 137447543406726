import { useEffect, useState } from "react";
import api from "../lib/api";
import ButtonChallenges from "../utils/ButtonChallenges";
import ButtonRemove from "../utils/ButtonRemove";
import Footer from "../utils/Footer";
import { formatDate } from "../utils/formatDate";
import ButtonOrange from "../utils/ButtonOrange";
import CreateChallenge from "../utils/CreateChallenge";
import { Trash } from "@phosphor-icons/react";

function Challenges() {
  const [days, setDays] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);
  const [createChallenge, setCreateChallenge] = useState<boolean>(false);


  useEffect(() => {
    async function getDays() {
      const response = await api.get("/days");
      setDays(response.data);
    }

    getDays();
  }, []);

  async function activeDay(day: Number) {
    for (let i = 0; i < days.length; i++) {
      if (days[i].isActive === true) {
        alert("Um dia já está ativo, desative-o para ativar outro");
        return;
      }
    }

    const response = await api.put("/days", {
      dayId: day,
      isActive: true
    });

    if (response.status === 200) {
      alert("Dia ativado com sucesso!");
      window.location.reload();
    } else {
      console.error("Erro no servidor:", response.status);
    }
  }

  async function disableDay(day: Number) {
    const response = await api.put("/days", {
      dayId: day,
      isActive: false
    });

    if (response.status === 200) {
      alert("Dia desativado com sucesso!");
      window.location.reload();
    } else {
      console.error("Erro no servidor:", response.status);
    }
  }
  async function getEvents() {
    await api.get("/events", {
      headers: {
        "Content-Type": "application/json"
      },
      validateStatus: () => true
    }).then((response) => {
      setEvents(response.data);
      console.log(response.data);
    })
  }

  async function deleteChallenge(id: string) {
    const response = await api.delete(`/challenges/${id}`);

  }

  useEffect(() => {
    getEvents();
  }, [])
  return (
    <div className="px-5 pb-10">
      <div className="flex justify-center items-center">
        <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">Desafios</h1>
      </div>
      <div>
      <div>
        {events.map((event) => (
          <div key={event.id} className="rounded-lg border-solid border p-4 mb-4 mx-20">
            <h2 className="text-lg font-semibold">{event.name}</h2>
            <p className="">Data de início: {formatDate(event.startAt, "date")}</p>
            <p className="">Dias de evento: {event.days}</p>
            <p className="">Status: {event.active ? 'Ativo' : 'Desativado'}</p>
            <p className="">Desafios:</p>
            <div className="flex flex-wrap gap-2 justify-center">
              {event.challenges.map((challenge: any) => (
                <div key={challenge.id} className="rounded-lg border-solid border p-2 w-[13%] my-2">
                <p>Nome: {challenge.title}</p>
                <p>Pontos: {challenge.points}</p>
                <Trash size={30} weight="bold" className="text-white bg-orange-500 p-1 rounded-lg cursor-pointer" onClick={() => deleteChallenge(challenge.id)}/>
                </div>
              ))}
            </div>
            {createChallenge && (<>
              <CreateChallenge eventId={event.id} setCreateChallenge={setCreateChallenge} getEvents={getEvents}/>
            </>)}
            <div className="flex justify-center">
            <div className="w-1/5">
              <ButtonOrange title="Adicionar desafio" onClick={() => setCreateChallenge(!createChallenge)} />
            </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default Challenges;
