import { useState } from "react";
import Loader from "./Loader/Loader";
import { FloppyDisk } from "@phosphor-icons/react";
import api from "../lib/api";
import { title } from "process";

interface CreateChallengeProps {
  eventId: string;
  setCreateChallenge: (value: boolean) => void;
  getEvents: () => void;
}

export default function CreateChallenge({eventId, setCreateChallenge, getEvents}: CreateChallengeProps) {
  const [name, setName] = useState<string>("");
  const [points, setPoints] = useState<number>(1);
  
  async function createChallenge() {
    const data = {
      title: name,
      points: points,
      eventId: eventId,
    }
    console.log(data);
    setCreateChallenge(false);
    getEvents()
    const response = await api.post("/challenges", data);
  }
  
  return (
    <div className="flex justify-center gap-10 py-4">
    
    <div className="w-1/3">
      <label htmlFor="name">Nome do desafio:</label>
      <input
        className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
        type="text"
        name="name"
        id="name"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setName(e.target.value);
        }}
        />
    </div>
    <div className="w-1/3">
      <label htmlFor="points">Pontos do desafio:</label>
      <input
        className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
        type="number"
        name="points"
        id="points"
        value={points}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPoints(parseInt(e.target.value));
        }}
        />
    </div>
    <div className="w-1/12 flex justify-center items-center">
      <FloppyDisk size={50} weight="bold" className="text-white bg-orange-500 p-2 rounded-lg cursor-pointer" onClick={createChallenge}/>
    </div>
    </div>
  );
}
